import {
  CertificationLevel,
  ApplicationReviewUsedFor,
  ApplicationReviewDetails,
  CommercialModel,
  Regions,
  ApplicationCategory,
  Industry,
  NoCodeAppType,
  ApplicationSubcategory,
} from 'types/applications/enums'
import { Leaves } from 'types/common/utils'
import { ApplicationsDictionaryTypes } from 'types/i18next'

export const noCodeAppTypeLabels: Record<NoCodeAppType, Leaves<ApplicationsDictionaryTypes['no_code_app_type']>> = {
  [NoCodeAppType.CODE_UPLOAD]: 'code_upload',
  [NoCodeAppType.CUSTOM_PAGE]: 'custom_page',
  [NoCodeAppType.EMBEDDED]: 'embedded',
  [NoCodeAppType.EMBEDDED_CODE]: 'embedded_code',
  [NoCodeAppType.EMBEDDED_LINK]: 'embedded_link',
  [NoCodeAppType.EXTERNAL_LINK]: 'external_link',
  [NoCodeAppType.MIRO_BOARD]: 'miro_board',
  [NoCodeAppType.PAGE_BUILDER]: 'page_builder',
}

export const CertificationLevelLabel: Record<CertificationLevel, Leaves<ApplicationsDictionaryTypes>> = {
  [CertificationLevel.CERTIFIED]: 'certification_levels.certified',
  [CertificationLevel.COMING_SOON]: 'certification_levels.coming_soon',
  [CertificationLevel.GARAGE]: 'certification_levels.garage',
  [CertificationLevel.STANDARD]: 'certification_levels.standard',
}

export type DescribedCertificationLevels = Extract<
  CertificationLevel,
  CertificationLevel.COMING_SOON | CertificationLevel.GARAGE
>

export const CertificationLevelDescription: Record<
  DescribedCertificationLevels,
  Leaves<ApplicationsDictionaryTypes>
> = {
  [CertificationLevel.COMING_SOON]: 'certification_level_description.coming_soon',
  [CertificationLevel.GARAGE]: 'certification_level_description.garage',
}

export const ApplicationReviewUsedForLabel: Record<ApplicationReviewUsedFor, Leaves<ApplicationsDictionaryTypes>> = {
  [ApplicationReviewUsedFor.PITCH_WON]: 'application_reviews.used_for.pitch_won',
  [ApplicationReviewUsedFor.PITCH_LOST]: 'application_reviews.used_for.pitch_lost',
  [ApplicationReviewUsedFor.DAY_TO_DAY_WORK]: 'application_reviews.used_for.day_to_day_work',
  [ApplicationReviewUsedFor.WORKSHOP]: 'application_reviews.used_for.workshop',
  [ApplicationReviewUsedFor.CAMPAIGN]: 'application_reviews.used_for.campaign',
  [ApplicationReviewUsedFor.OTHER]: 'application_reviews.used_for.other',
}

export const ApplicationReviewDetailsLabel: Record<ApplicationReviewDetails, Leaves<ApplicationsDictionaryTypes>> = {
  [ApplicationReviewDetails.EASY_TO_USE]: 'application_reviews.details.easy_to_use',
  [ApplicationReviewDetails.GREAT_FEATURE_SET]: 'application_reviews.details.great_feature_set',
  [ApplicationReviewDetails.EFFICIENT_WORKFLOW]: 'application_reviews.details.efficient_workflow',
  [ApplicationReviewDetails.INTUITIVE_DESIGN]: 'application_reviews.details.intuitive_design',
  [ApplicationReviewDetails.FAST_LOADING_TIMES]: 'application_reviews.details.fast_loading_times',
  [ApplicationReviewDetails.WORKS_AS_EXPECTED]: 'application_reviews.details.works_as_expected',
  [ApplicationReviewDetails.DIFFICULT_TO_USE]: 'application_reviews.details.difficult_to_use',
  [ApplicationReviewDetails.SLOW_LOADING_TIMES]: 'application_reviews.details.slow_loading_times',
  [ApplicationReviewDetails.MISSING_KEY_FEATURES]: 'application_reviews.details.missing_key_features',
  [ApplicationReviewDetails.BUGS_AND_ISSUES]: 'application_reviews.details.bugs_and_issues',
}

export const CommercialModelLabel: Record<CommercialModel, Leaves<ApplicationsDictionaryTypes>> = {
  [CommercialModel.CONTRACT]: 'commercial_model_labels.contract',
  [CommercialModel.FIXED_PRICE]: 'commercial_model_labels.fixed_price',
  [CommercialModel.FREE]: 'commercial_model_labels.free',
  [CommercialModel.PAY_AS_YOU_GO]: 'commercial_model_labels.pay_as_you_go',
  [CommercialModel.FREEMIUM]: 'commercial_model_labels.freemium',
}

export const RegionsLabel: Record<Regions, Leaves<ApplicationsDictionaryTypes>> = {
  [Regions.EMEA]: 'regions.EMEA',
  [Regions.APAC]: 'regions.APAC',
  [Regions.NA]: 'regions.NA',
  [Regions.LATAM]: 'regions.LATAM',
  [Regions.GLOBAL]: 'regions.GLOBAL',
}

export const ApplicationCategoryLabel = {
  [ApplicationCategory.MEDIA]: 'media',
  [ApplicationCategory.PRODUCTION]: 'production',
  [ApplicationCategory.BRAND]: 'brand',
  [ApplicationCategory.COMMERCE]: 'commerce',
  [ApplicationCategory.CREATIVE]: 'creative',
  [ApplicationCategory.EXPERIENCE]: 'experience',
  [ApplicationCategory.PR]: 'pr',
  [ApplicationCategory.DATA]: 'data',
  [ApplicationCategory.OTHER_APPS]: 'other_apps',
} as const

export const ApplicationSubcategoryLabel = {
  [ApplicationSubcategory.DISCOVER]: 'discover',
  [ApplicationSubcategory.PLAN]: 'plan',
  [ApplicationSubcategory.ACTIVATE]: 'activate',
  [ApplicationSubcategory.MEASURE]: 'measure',
  [ApplicationSubcategory.ADAPT]: 'adapt',
  [ApplicationSubcategory.GENERATE]: 'generate',
  [ApplicationSubcategory.IDEATE]: 'ideate',
  [ApplicationSubcategory.MAKE]: 'make',
  [ApplicationSubcategory.OPTIMISE]: 'optimise',
  [ApplicationSubcategory.PUBLISH]: 'publish',
} as const

export const ApplicationIndustryLabel: Record<Industry, Leaves<ApplicationsDictionaryTypes>> = {
  [Industry.AUTOMOTIVE]: 'industries.automotive',
  [Industry.BUSINESS_AND_PROFESSIONAL_SERVICES]: 'industries.business_and_professional_services',
  [Industry.CPG]: 'industries.cpg',
  [Industry.ENERGY_AND_INFRASTRUCTURE]: 'industries.energy_and_infrastructure',
  [Industry.FINANCIAL_SERVICES]: 'industries.financial_services',
  [Industry.GOVERNMENT_AND_PUBLIC_SECTOR]: 'industries.government_and_public_sector',
  [Industry.HEALTHCARE_AND_PHARMA]: 'industries.healthcare_and_pharma',
  [Industry.LUXURY_AND_PREMIUM]: 'industries.luxury_and_premium',
  [Industry.RETAIL]: 'industries.retail',
  [Industry.TECH_AND_DIGITAL_SERVICES]: 'industries.tech_and_digital_services',
  [Industry.TELECOMS]: 'industries.telecoms',
  [Industry.MEDIA_AND_ENTERTAINMENT]: 'industries.media_and_entertainment',
  [Industry.TRAVEL_AND_LEISURE]: 'industries.travel_and_leisure',
}
